import React from "react";
import { Routes, Route } from "react-router-loading";

import { topbar } from "react-router-loading";
const Dashboard = React.lazy(() => import('./dashboard.js'));
const Home = React.lazy(() => import('../pages/home.js'));
const Login = React.lazy(() => import('../pages/login.js'));
const Services = React.lazy(() => import('../pages/services.js'));
const Account = React.lazy(() => import('../pages/account.js'));
topbar.config({
    autoRun: true,
    barThickness: 5,
    barColors: {
        0: 'rgba(26,  188, 156, .7)',
        .3: 'rgba(41,  128, 185, .7)',
        1.0: 'rgba(231, 76,  60,  .7)'
    },
    shadowBlur: 5,
    shadowColor: 'red',
    className: 'topbar'
});

/*Routes is used to be Switch*/
const Router = () => {
  /* nesting routes*/
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} >
        <Route path="/home/" element={<Home />} />
        <Route path="/services/" element={<Services />} />
        <Route path="/account/" element={<Account />} />
      </Route>
      <Route path="/login/" element={<Login />} />
    </Routes>
  );

  /* object-based routes
  return useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "games", element: <Games /> },
    { path: "game-details/:id", element: <GameDetails /> },
    {
      path: "dashboard",
      element: <Dashboard />,
      children: [
        { path: "/", element: <DashboardDefaultContent /> },
        { path: "inbox", element: <Inbox /> },
        { path: "settings-and-privacy", element: <SettingsAndPrivacy /> },
        { path: "*", element: <NotFound /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
*/
};
export default Router;
